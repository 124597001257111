/* Add this style to your SearchComponent.css file or create a new CSS file */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 24px;
  margin-bottom: 20px;
}


.search-container {
  width: 250px;
  padding-bottom: 10px;
}

@media screen and (max-width: 700px) {
  .search-container {
    width: 100%;
  }
}


.error {
  color: #ff5050;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
}
